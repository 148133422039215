import { registerLocale } from "react-datepicker";
import {
  Flex,
  Box,
  extendTheme,
} from "@chakra-ui/react";
import { useActiveHospital } from "../../contexts/ActiveHospital/useActiveHospital";

import GridAttendance from "../../components/GridAttendance";
import ptBR from "date-fns/locale/pt-BR";

import "react-datepicker/dist/react-datepicker.css";

const breakpoints = {
  sm: "320px",
  md: "768px",
  lg: "960px",
  xl: "1200px",
  "2xl": "1536px",
};

extendTheme({ breakpoints });

function Exams() {
  registerLocale("ptBR", ptBR);
  const activeHospital = useActiveHospital();

  const itemsPerPage = 7;

  return (
    <Flex backgroundColor="#F7F8FC" minW="100%">
      <Box
        p="1.5rem"
        w="100%"
      >
        <GridAttendance
          pageSize={itemsPerPage}
          uuidSelected={activeHospital.uuid}
        />
      </Box>
    </Flex>
  );
}

export default Exams;
