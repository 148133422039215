import { useState, useEffect, useCallback } from "react";
import { registerLocale } from "react-datepicker";
import { useAuth, useExams, useHospitals, useUsers } from "../../hooks";
import {
	Tabs,
	TabList,
	Tab,
	TabPanels,
	TabPanel,
	Flex,
	Box,
	extendTheme,
	useDisclosure,
} from "@chakra-ui/react";

import ptBR from "date-fns/locale/pt-BR";
import GridAuthorization from "../../components/GridAuthorization";
import GridAppointment from "../../components/GridAppointment";
import ModalNotification from "../../components/ModalNotification";
import GridAttendance from "../../components/GridAttendance";
import ModalSelectHospital from "../../components/ModalSelectHospital";

import "react-datepicker/dist/react-datepicker.css";
import './style.scss';
import { useActiveHospital } from "../../contexts/ActiveHospital/useActiveHospital";
import axios from "axios";
import { getHospitals } from "../../contexts/ActiveHospital/ChangeActiveHospitalModal/utils";

const breakpoints = {
	sm: "320px",
	md: "768px",
	lg: "960px",
	xl: "1200px",
	"2xl": "1536px",
};

extendTheme({ breakpoints });

function Dashboard() {
	registerLocale("ptBR", ptBR);

	const { listAppointment, listAuthorization } = useExams();
	const { user } = useAuth();
	const { getHospitalNotification } = useHospitals();
	const { onClose } = useDisclosure();
	const activeHospital = useActiveHospital();

	const [isModalNotificationOpen, setIsModalNotificationOpen] = useState(true)

	const [showAppointment, setShowAppointment] = useState(null);
	const [showAuthorization, setShowAuthorization] = useState(null);
	const [showDashboard, setShowDashboard] = useState(null);

	const [uuidUser, setUUIDUser] = useState(activeHospital.uuid);
	const [permissions, setPermissions] = useState();

	const itemsPerPage = 3;
	const [pageCountAppointment, setPageCountAppointment] = useState(0);
	const [pageCountAuthorization, setPageCountAuthorization] = useState(0);
	const [currentPageAppointment, setCurrentPageAppointment] = useState(0);
	const [currentPageAuthorization, setCurrentPageAuthorization] = useState(0);

	const [notificationList, setNotificationList] = useState([]);

	const handleChildClick = () => {
		getAppointmentList(uuidUser);
	};

	useEffect(() => {
		getAppointmentList(uuidUser);
		getAuthorizationList();
	}, [currentPageAppointment, currentPageAuthorization]);

	async function listHospitalNotification(uuidChanged) {
		if (!uuidChanged) {
			return
		}

		setNotificationList(await getHospitalNotification(uuidChanged));
	}

	async function getAuthorizationList() {
		if (!uuidUser) {
			return;
		}

		try {
			const params = {
				pageNo: currentPageAuthorization ? currentPageAuthorization + 1 : 1,
				pageSize: itemsPerPage,
				Order: "DESC",
			};
			const response = await listAuthorization(uuidUser, user.role_id, params);

			setShowAuthorization(response?.Authorization?.[0]?.authorization_list);
			setPageCountAuthorization(response?.Authorization[0]?.total_pages);
		} catch (error) {
			console.error(error);
		}
	}

	async function getAppointmentList(uuidUser) {
		if (!uuidUser) {
			return;
		}

		try {
			const params = {
				pageNo: currentPageAppointment ? currentPageAppointment + 1 : 1,
				pageSize: itemsPerPage,
				Order: "DESC",
			};

			const response = await listAppointment(uuidUser, user.role_id, params);

			setShowAppointment(response?.Appointment?.[0]?.appointment_list);
			setPageCountAppointment(response?.Appointment[0]?.total_pages);
		} catch (error) {
			console.error(error);
		}
	}

	useEffect(() => {
		setUUIDUser(activeHospital.uuid);
		setPermissions(activeHospital.permissions);
		getAppointmentList(activeHospital.uuid);
		listHospitalNotification(activeHospital.uuid);
	}, [activeHospital.uuid]);

	useEffect(() => {
		getAuthorizationList();

		if (uuidUser !== undefined) {
			setShowDashboard(true);
		}
	}, [uuidUser]);


	return (
		<Flex>
			<Box className="dashboard_container">
				{showDashboard && (
					<Box
						m="18px 0"
						p="10px 20px"
						border="1px solid #CED4DA"
						borderRadius="10px"
						backgroundColor="var(--cinza-bg)"
					>
						<Tabs variant='soft-rounded' colorScheme='green'>
							<TabList style={{ overflow: 'auto' }}>
								{
									(
										(user?.role_id === 1)
										|| permissions?.find(item => item === 1)
										|| permissions?.find(item => item === 2)

									) && (
										<Tab
											textColor="#F3953F"
											fontWeight={800}
											id="tabAgendamento"
											children='Agendamento'
										/>
									)
								}

								<Tab textColor="#F3953F" fontWeight={800} id="tabAutorizacao">Autorização</Tab>

								{(
									user?.role_id === 1 || user?.role_id === 3
									|| permissions?.find(item => item === 1)
									|| permissions?.find(item => item === 3)
								) && <Tab textColor="#F3953F" fontWeight={800} id="tabResultado">Resultado</Tab>}
							</TabList>
							<TabPanels>
								{(
									(user?.role_id === 1)
									|| permissions?.find(item => item === 1)
									|| permissions?.find(item => item === 2)
								) && (
										<TabPanel>
											<GridAppointment
												showAppointment={showAppointment}
												pageCountAppointment={pageCountAppointment}
												currentPageAppointment={currentPageAppointment}
												setCurrentPageAppointment={setCurrentPageAppointment}
												onChildClick={handleChildClick}
											/>
										</TabPanel>
									)}
								<TabPanel>
									<GridAuthorization
										showAuthorization={showAuthorization}
										pageCountAuthorization={pageCountAuthorization}
										currentPageAuthorization={currentPageAuthorization}
										setCurrentPageAuthorization={setCurrentPageAuthorization}
									/>
								</TabPanel>
								{(
									(user?.role_id === 1 || user?.role_id === 3)
									|| permissions?.find(item => item === 1)
									|| permissions?.find(item => item === 3)

								) && (
										<TabPanel>
											<GridAttendance
												uuidSelected={uuidUser}
												pageSize={10}
											/>
										</TabPanel>
									)}
							</TabPanels>
						</Tabs>
					</Box>
				)}
			</Box>


			{(
				user.role_id === 2 &&
				notificationList.length > 0 &&
				uuidUser
			) && (
					<ModalNotification
						notificationList={notificationList}
						onClose={onClose}
						setIsModalNotificationOpen={setIsModalNotificationOpen}
						isOpen={isModalNotificationOpen}
					/>
				)
			}
		</Flex>
	);
}

export default Dashboard;
