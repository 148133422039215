import React, { CSSProperties, FormEvent, RefObject } from 'react';

interface FormProps<T = any> {
  children: React.ReactNode;
  onFinish: (formData: T, event: FormData) => void;
  className?: string;
  refObject?: RefObject<HTMLFormElement>;
  style?: CSSProperties;
}

const Form = ({ children, onFinish, className, refObject, style }: FormProps) => {

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const formValues = Object.fromEntries(formData.entries());

    onFinish(formValues, formData);
  };

  return (<form ref={refObject} className={className} style={style} onSubmit={handleSubmit}>{children}</form>);
};

export default Form;