import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useAuth, useUsers } from "../hooks/index.js";
import { formatDate, formatHour } from "../helpers/index.js";

import styled from "styled-components";

// import logo from "../images/logo-dash.svg";
// import principalChecked from "../images/principal-checked.svg";
// import resultadoExame from "../images/resultado-exame.svg";
// import agendamento from "../images/agendamento.svg";
// import usuarios from "../images/usuarios.svg";
// import sair from "../images/sair.svg";

import CalendarSidebarIcon from "../assets/icons/CalenarSidebarIcon.tsx";
import ExamSidebarIcon from "../assets/icons/ExamSidebarIcon.tsx";
import ExitIcon from "../assets/icons/ExitIcon.tsx";
import LogoIcon from "../assets/icons/LogoIcon.tsx";
import UserSidebarIcon from "../assets/icons/UserSidebarIcon.tsx";
import HouseIcon from "../assets/icons/HouseIcon.tsx";

import { theme } from "../styles/theme.tsx";

// import { Box, extendTheme, Button } from "@chakra-ui/react";

// const breakpoints = {
//   sm: "320px",
//   md: "768px",
//   lg: "960px",
//   xl: "1200px",
//   "2xl": "1536px",
// };

// const theme = extendTheme({ breakpoints });
export default function SideBar({ permissions }) {

  const { user, logout } = useAuth();
  const { getUser } = useUsers();
  const today = formatDate(new Date(), "extense");
  const [currentHour, setCurrentHour] = useState(formatHour(new Date()));
  const [userLogged, setUserLogged] = useState();
  //const [widthScreen, setWidthScreen] = useState(0);

  const [bgResult, setBGResult] = useState(0);
  const [bgUser, setBGUser] = useState(0);

  const location = useLocation();
  const refreshClock = () => {
    setCurrentHour(formatHour(new Date()));
  };

  async function getUserLogged() {

    if (!user) {
      try {
        const result = await getUser(user.id);
        setUserLogged(result.users);
      } catch (error) { }
    } else {
      setUserLogged(user);
    }

    console.log("userLogged", userLogged);
  }

  async function backgrandButton() {
    switch (location.pathname) {
      case '/users':
        setBGUser(true);
        break;
      case '/resultados-exames':
        setBGResult(true);
        break;
      default:
        setBGResult(false);
        setBGUser(false);
        break;

    }
  }

  useEffect(() => {
    backgrandButton();

    getUserLogged();
    const timerId = setInterval(refreshClock, 1000);
    return function cleanup() {
      clearInterval(timerId);
    };


  }, []);

  // function Mudarestado(el) {
  //   var display = document.getElementById(el).style.display;
  //   if (display === "none") {
  //     document.getElementById(el).style.display = "block";
  //     setWidthScreen("200px");
  //   } else {
  //     document.getElementById(el).style.display = "none";
  //     setWidthScreen("0");
  //   }
  // }



  return (

    <Container>
      <div style={{ padding: "0px 10px" }}>
        <NavLink to="/">
          <LogoIcon />
        </NavLink>
      </div>
      <Content>
        <div>

          {

            userLogged && userLogged.role_id === 4 ? (

              <Link to="/resultados-exames" >
                <HouseIcon />
                <span>Início</span>
              </Link>

            ) : (

              <Link to="/dashboard" >
                <HouseIcon />
                <span>Início</span>
              </Link>

            )
          }

          {
            userLogged
            &&
            (
              userLogged.role_id === 1
              || (


                permissions?.find(item => item === 1)
                || permissions?.find(item => item === 2)

              )

              //&&
              //(   


              //(user?.permissoes.findIndex(item => item.id === '1')) > -1  
              // || 
              // (user?.permissoes.findIndex(item => item.id === '2')) > -1 
              //)
            )
            && (
              <ExternalLink
                // href={`http://localhost:3001/login/sso?token=${localStorage.getItem("Auth_senne")}&hospitalUuid=${localStorage.getItem("hospital")}&userId=${localStorage.getItem("User_id_senne")}`}
                href={`${process.env.REACT_APP_SCHEDULE_URL}/login/sso?token=${localStorage.getItem("Auth_senne")}&hospitalUuid=${localStorage.getItem("hospital")}&userId=${localStorage.getItem("User_id_senne")}`}
              >
                <CalendarSidebarIcon />
                <span>Agendamento</span>
              </ExternalLink>
            )
          }

          {/* {
              (userLogged &&
              ((userLogged.role_id === 2 
                &&
                (   
     
                  permissions?.find(item => item === 1) ||
                  permissions?.find(item => item === 3)
                )

                  ) || userLogged.role_id === 1)) ||
              (userLogged && userLogged.role_id === 3) ? (
                <Link to="/resultados-exames" selected={bgResult}>

                      <ExamSidebarIcon />

                      <span className="title-item">
                        Resultados de Exames
                      </span>

                </Link>
                ) 
                : 
                null
              } */}

          {
            (userLogged && userLogged.role_id === 1)
              ?
              (
                <Link to="/gerenciamento-senne" selected={bgUser}>

                  <UserSidebarIcon />
                  <span className="title-item">Usuários</span>
                </Link>
              ) :
              userLogged &&
                userLogged.role_id === 2

                &&
                permissions?.find(item => item === 1)

                ? (
                  <Link to="/users" selected={bgUser}>
                    <UserSidebarIcon />
                    <span >Usuários</span>
                  </Link>
                ) : null}

        </div>

        <Footer>

          <span className="hour">{currentHour}</span>
          <span className="data-title">{today}</span>


          <button className="exit" onClick={logout}>
            <ExitIcon />
            <span className="title-item-sair">Sair</span>
          </button>
        </Footer>
      </Content>

    </Container>

  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.white};
  border-radius: 0px 15px 15px 0px;
  box-shadow: 0px 5.06787px 17.7376px rgba(0, 0, 0, 0.25);
  padding: 16px 0px;

  height: 100%;
  height: 100vh;
  width: 200px;


  div {
    display: flex;
    flex-direction: column;
    gap: 1px;
  }
`;

const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 0px;
  align-items: center;
`;


const Link = styled(NavLink)`
  display: flex;
  align-items: center;
  padding: 16px;
  width: 180px;
  font-weight: 500;
  color: ${props => props.selected ? theme.colors.white : theme.colors.textGray};

  font-family: Montserrat;
  background-color: ${props => props.selected ? 'rgb(243, 149, 63, 0.7)' : theme.colors.white};

  :hover {
    background-color: rgb(243, 149, 63, 0.7);
    color: ${theme.colors.white};

    svg {
      .icon {
        fill: ${theme.colors.tertiary};
      }
    }
  }

  span {
    margin-left: 8px;
  }
  svg {
    .icon {
      fill: ${props => props.selected ? theme.colors.tertiary : theme.colors.textGray};
    }
  }
`;


const ExternalLink = styled.a`
  display: flex;
  align-items: center;
  padding: 16px;
  width: 180px;
  font-weight: 500;
  color: ${theme.colors.textGray};
  font-family: Montserrat;

  svg {
    .icon {
      fill: ${theme.colors.textGray};
    }
  }

  :hover {
    background-color: rgb(243, 149, 63, 0.7);
    color: ${theme.colors.white};

    svg {
      .icon {
        fill: ${theme.colors.tertiary};
      }
    }
  }

  span {
    margin-left: 8px;
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  margin: 0px 8px;
  width: 180px;

  .hour {
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 10px;
  }

  .exit {
    cursor: pointer;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    gap: 6px;
    color: ${theme.colors.tertiary};
    font-weight: 800;
  }
`;