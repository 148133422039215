import { IInputType, IReportParams } from "./types";

import api from "../../services/api";

export const REPORT_INPUT_TYPE_LIST: IInputType[] = ["date", "input", "select"];

export const getReportParams = async (reportId: number, signal?: AbortSignal) => {
    return (await api.get<{ params: IReportParams[] }>(`/report/param/${reportId}`, { signal })).data.params;
}

export const generateReport = async (reportId: number, params: object, signal?: AbortSignal) => {
    return await api.post<ArrayBuffer>(`/report/${reportId}`, params, { responseType: 'arraybuffer', signal });
}

export const generateFilename = (baseName: string, extension: string): string => {
    const formattedDate = (new Date()).toISOString().replace(/:/g, '-').split('.')[0];
    return `${baseName}-${formattedDate}.${extension}`;
}
