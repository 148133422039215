import { Skeleton } from "@chakra-ui/react";

const ReportSkeleton = () => {
    return (
        <div className="report">
            <div className="title">
                <Skeleton
                    width='276px'
                    height='31px'
                />
                <Skeleton
                    width='36px'
                    height='31px'
                />
            </div>
            <div className="params">
                <span className="title">
                    <Skeleton
                        width='100px'
                        height='24px'
                    />
                </span>
                <div className="fields">
                    <div className="field">
                        <Skeleton
                            width='90px'
                            height='19px'
                        />
                        <Skeleton
                            marginTop='.3rem'
                            width='100%'
                            height='31px'
                        />
                    </div>
                    <div className="field">
                        <Skeleton
                            width='90px'
                            height='19px'
                        />
                        <Skeleton
                            marginTop='.3rem'
                            width='100%'
                            height='31px'
                        />
                    </div>
                    <div className="field">
                        <Skeleton
                            width='90px'
                            height='19px'
                        />
                        <Skeleton
                            marginTop='.3rem'
                            width='100%'
                            height='31px'
                        />
                    </div>
                </div>
                <div className="actions">
                    <Skeleton
                        className="generate"
                        width='100%'
                        height='27px'
                    />
                </div>
            </div>
        </div>
    );
}

export default ReportSkeleton;