import { ITableProps } from './types';
import { Tooltip } from '@chakra-ui/react';

import classNames from 'classnames';

import './style.scss';

export const Table = ({
	thead,
	tbody,
	maxHeightFix,
	emptyMessage,
	variant,
	hover
}: ITableProps) => {

	variant = variant ? variant : 'unabled--dark';

	return (
		<>
			<div className={classNames('table', `--${variant}`, { '--hover': hover })} style={{ maxHeight: maxHeightFix ? `calc(100vh - ${maxHeightFix}rem)` : '' }}>
				<table>
					{thead && <thead><tr>{thead.map((th, index) => <th key={index} className={th.className}>{th.content}</th>)}</tr></thead>}
					<tbody>
						{tbody.length === 0 ?
							<tr><td className='--empty_message' colSpan={thead?.length}>{emptyMessage}</td></tr> :
							tbody.map((tr, index) =>
								<tr key={index} className={tr.className} onClick={tr.onClick}>
									{tr.elements.map((td, index) =>
										<td className={td.className} style={td.style} key={index}>
											{td.tooltip ?
												<Tooltip
													hasArrow
													label={td.content}
													children={td.content}
													bg="gray.300"
													color="black"
												/> : td.content
											}
										</td>
									)}
								</tr>
							)}
					</tbody>
				</table>
			</div>
		</>
	);
}