export type RolePatient = 3;
export type RoleHospitals = 2;
export type RoleDoctor = 4;
export type RoleOperation = 1;

export type Role =
    RolePatient |
    RoleHospitals |
    RoleDoctor |
    RoleOperation;

export const ROLE_PATIENT: RolePatient = 3;
export const ROLE_HOSPITALS: RoleHospitals = 2;
export const ROLE_DOCTOR: RoleDoctor = 4;
export const ROLE_OPERATION: RoleOperation = 1;
