import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { BreadCrumbs } from "../../components/Breadcrumb2";
import { useUsers } from "../../hooks";
import { Flex, Box, Text, Switch, Button } from "@chakra-ui/react";
import AproveDoctor from "./AproveDoctor";
import arrowLeft from "../../images/arrowLeft.svg"
import ProfileImage from "../../images/profile.png";
import JournalCheck from "../../images/journal-check.svg";
import { CancelButton } from "../../components/Buttons";
import { FormatDate, FormatHour } from "../../components/Format";
import { GoBackButton, GrayButton } from "../../components/Buttons";
import { useHospitals, useAuth } from "../../hooks";
import { PERMISSION_REPORTS } from "../../types/permissions";
import { useQuery } from "@tanstack/react-query";
import { useActiveHospital } from "../../contexts/ActiveHospital/useActiveHospital";
import api from "../../services/api";

const getReportList = async (uuid) => (await api.get('/report', { params: { uuid } })).data.reports;

const Profile = () => {
  const listReportsRef = useRef(null);

  const activeHospital = useActiveHospital();
  const { getUserHospitalList } = useHospitals();
  const { id: userId } = useParams();
  const { getLogs, getUser } = useUsers();
  const [userProfile, setUserProfile] = useState();
  const [listLogs, setListLogs] = useState();
  const { inactiveUser, approveUser } = useUsers();
  const [openAprove, setOpenAprove] = useState();
  const [loading, setLoading] = useState(false);

  const { user } = useAuth();

  const [hospitalsListNoPagination, setHospitalsListNoPagination] = useState(
    [],
  );

  const hasReport = !!userProfile?.hospitals[0]?.permissions.includes(PERMISSION_REPORTS);
  const { data: reportList } = useQuery({
    queryKey: ['reports', user.role_id === 1 ? { role_id: 1 } : { uuid: activeHospital.uuid }],
    queryFn: () => {
      listReportsRef.current?.abort();
      listReportsRef.current = new AbortController();

      return getReportList(activeHospital.uuid);
    },
    enabled: hasReport,
    retry: false,
    staleTime: 1 * 60 * 60 * 1000
  });

  const [permissions, setPermissions] = useState();

  const cpfMask = (value) => {
    return value;
    // .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
    // .replace(/(\d{3})(\d)/, "$1.$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    // .replace(/(\d{3})(\d)/, "$1.$2")
    // .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    // .replace(/(-\d{2})\d+?$/, "$1"); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
  };

  async function getUserHospitalsList() {

    const response = await getUserHospitalList();

    const { hospitals } = response;

    // console.log("hospitals", hospitals)

    setHospitalsListNoPagination(hospitals);

    return hospitals;
  }

  async function getUserPermission(defaultHospital) {
    const hospitals = await getUserHospitalsList();
    const { permissions } = hospitals.find((hospital) => hospital.uuid === defaultHospital);
    setPermissions(permissions);
  }

  async function AproveUsers(id, status) {
    if (status === 3) {
      const data = {
        id: id,
      };
      try {
        await approveUser(id, data);
      } catch (error) {
        console.log(error);
      }
    }
    if (status === 1 || status === 0) {
      const data = { status: status === 0 ? 1 : 0 };
      try {
        setLoading(true);
        await inactiveUser(id, data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
    getUserProfile();
  }


  function getUserProfile() {
    getUser(parseInt(userId)).then((data) => {
      const users_formatted = {};
      let permissions
      if (data.users.role_id === 2) {
        permissions = data.users.hospitals[0].permissions.map((item) => item);

        if (permissions.includes(1)) {
          users_formatted.administrator = true;
        }

        if (permissions.includes(2)) {
          users_formatted.scheduling = true;
        }

        if (permissions.includes(3)) {
          users_formatted.results = true;
        }

      }
      setUserProfile({ ...data.users, ...users_formatted });

    });
  }
  const navigate = useNavigate()
  useEffect(() => {
    if (userId) {
      getUserProfile();
    }
  }, [userId, openAprove]);

  const gettingRole = () => {
    if (user.role_id === 1) { return 'Administrador Senne' }
    if (user.role_id === 2) { return 'Hospital' }
    if (user.role_id === 3) return 'Paciente'
    if (user.role_id === 4) return 'Médico'
  }

  return (
    <>
      {userProfile && (
        <Flex>
          <Box p="24px">

            <GoBackButton click={() => navigate('/users')} />
            <Flex
              mt="24px"
              border="1px solid var(--cinza-card)"
              borderRadius="8px"
              p="46px 60px"
              backgroundColor="white"
              flexDirection="row"
            >

              <Flex
                width="200px"
                alignItems="start"
                justifyContent="space-between"
                flexDirection="column"
                borderRight="1px solid var(--orange-senne)"
                pr="60px"
                mr="30px"
              >

                <CardImage>
                  <img src={userProfile?.image ?? ProfileImage} alt="" />
                </CardImage>

                <Text color="var(--orange-senne)" as="strong">
                  { }
                </Text>

                {userProfile.role_id !== 4 ? (
                  <Box>
                    <Box
                      m="0 auto"
                      w="160px"
                      pb="24px"
                      borderBottom="1px solid var(--orange-senne)"
                    >
                      <Text color="var(--orange-senne)" as="strong">
                        { }
                      </Text>
                      <Text>Grupo</Text>
                      <Text as="strong">{userProfile?.hospitals[0]?.groupName}</Text>
                      <Text>Hospital</Text>
                      <Text as="strong">{userProfile?.hospitals[0]?.name}</Text>
                      <Box m="16px auto 0" w="160px">
                        <Text as="strong">Editar Perfis</Text>
                        <Flex
                          flexDirection="column"
                          gap="10px"
                          mt="16px"
                          alignItems="start"
                        >
                          <Flex
                            justifyContent="center"
                            alignContent="center"
                            alignItems="center"
                            gap="10px"
                          >
                            <Switch
                              size="md"
                              colorScheme="orange"
                              id="scheduling"
                              name="scheduling"
                              defaultChecked={userProfile?.scheduling}
                              isDisabled
                            />
                            <Text w="200px">Agendamento</Text>
                          </Flex>
                          <Flex
                            justifyContent="center"
                            alignContent="center"
                            alignItems="center"
                            gap="10px"
                          >
                            <Switch
                              size="md"
                              colorScheme="orange"
                              id="administrator"
                              name="administrator"
                              defaultChecked={userProfile?.administrator}
                              isDisabled
                            />
                            <Text>Administrador</Text>
                          </Flex>
                          <Flex
                            justifyContent="center"
                            alignContent="center"
                            alignItems="center"
                            gap="10px"
                          >
                            <Switch
                              size="md"
                              colorScheme="orange"
                              id="results"
                              name="results"
                              defaultChecked={userProfile?.results}
                              isDisabled
                            />
                            <Text>Resultados</Text>
                          </Flex>
                          {
                            userProfile.role_id === 2 ?
                              (
                                <Flex
                                  justifyContent="center"
                                  alignContent="center"
                                  alignItems="center"
                                  gap="10px"
                                >
                                  <Switch
                                    size="md"
                                    colorScheme="orange"
                                    id="permiteContato"
                                    name="permiteContato"
                                    defaultChecked={userProfile?.permite_contato === 1}
                                    isDisabled
                                  />
                                  <Text>Ponto Focal para Resultados Críticos</Text>
                                </Flex>
                              ) :
                              null
                          }
                          {/* <Flex
                      justifyContent="center"
                      alignContent="center"
                      alignItems="center"
                      gap="10px"
                    >
                      <Switch size="md" colorScheme="orange" />
                      <Text>Administrador Master</Text>
                    </Flex> */}

                          <Flex
                            justifyContent="center"
                            alignContent="center"
                            alignItems="center"
                            gap="10px"
                          >
                            <Switch
                              size="md"
                              colorScheme="orange"
                              name="report"
                              id="report"
                              defaultChecked={!!hasReport}
                              isDisabled
                            />
                            <Text>Relatórios</Text>
                          </Flex>
                          {hasReport &&
                            <ReportList>
                              <select
                                name="report_permissions"
                                id="report_permissions"
                                multiple
                                defaultValue={userProfile.report_ids}
                                disabled
                              >
                                {reportList.map((report, index) => <option key={index} value={report.id}>{report.label ?? report.name}</option>)}
                              </select>
                            </ReportList>
                          }
                        </Flex>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  ""
                )}
              </Flex>

              <Flex width="calc(100% - 200px)" flexDirection="column" pl="16px">
                <Flex width="100%" justifyContent="space-between">
                  <Text mb="24px" as="strong">
                    Informações Básicas
                  </Text>
                  <Link to={`/users/${userProfile?.id || "999"}/editar`}>
                    <Box w="108px" h="28px">
                      <CancelButton>Editar Perfil</CancelButton>
                    </Box>
                  </Link>
                </Flex>

                <Flex
                  width="100%"
                  justifyContent="space-between"
                  flexWrap="wrap"
                >
                  <Box w="50%" pr="16px" mb="16px">
                    <Text as="strong">Nome Completo</Text>
                    <Text>{userProfile?.name}</Text>
                  </Box>
                  {userProfile.role_id !== 4 ? (
                    <Box w="50%" pr="16px" mb="16px">
                      <Text as="strong">CPF</Text>
                      <Text>{cpfMask(userProfile?.cpf)}</Text>
                    </Box>
                  ) : (
                    <Box w="50%" pr="16px" mb="16px">
                      <Text as="strong">CRM</Text>
                      <Text>{cpfMask(userProfile?.crm)}</Text>
                    </Box>
                  )}
                  <Box w="50%" pr="16px" mb="16px">
                    <Text as="strong">Telefone</Text>
                    <Text>{userProfile?.cellphone}</Text>
                  </Box>
                  <Box w="50%" pr="16px" mb="16px">
                    <Text as="strong">Email</Text>
                    <Text>{userProfile?.email}</Text>
                  </Box>
                  {userProfile.role_id !== 4 && (
                    <Box w="50%" pr="16px" mb="16px">
                      <Text as="strong">Unidade</Text>
                      <Text>{userProfile?.hospitals[0]?.name}</Text>
                    </Box>
                  )}
                  <Box w="50%" pr="16px" mb="16px">
                    <Button
                      backgroundColor={
                        userProfile?.status === 0
                          ? "var(--black-50)"
                          : userProfile?.status === 1
                            ? "var(--blue-senne)"
                            : userProfile?.status === 2
                              ? "var(--orange-senne)"
                              : "var(--orange-senne-secundary)"
                      }
                      color="white"
                      _hover={{ opacity: 0.6 }}
                      onClick={() =>
                        userProfile?.status === 3 && userProfile?.role_id === 4
                          ? setOpenAprove(true)
                          : AproveUsers(userProfile.id, userProfile.status)
                      }
                      disabled={userProfile.status === 2 ? true : false}

                      isLoading={loading}
                      spinnerPlacement='end'
                      maxW={300}
                      w={200}
                    >
                      {userProfile?.status === 0
                        ? "Ativar Usuário"
                        : userProfile?.status === 1
                          ? "Inativar Usuário"
                          : userProfile?.status === 2
                            ? "Pendente de Ativaçao"
                            : userProfile?.status === 3
                              ? "Aprovar Usuário"
                              : userProfile?.status === 4
                                ? "Pendente de Ativaçao SMS"
                                : ""}
                    </Button>
                  </Box>
                </Flex>

                <Box
                  w="100%"
                  border="1px solid var(--cinza-card)"
                  borderRadius="8px"
                  p="16px"
                  overflow
                >
                  <Flex alignItems="center" mb="16px">
                    <img src={JournalCheck} alt="" />
                    <Text ml="16px" as="strong">
                      Últimas atualizações
                    </Text>
                  </Flex>

                  <Box maxHeight="250px" overflowY="auto">
                    {listLogs?.map((data, index) => (
                      <Flex
                        key={index}
                        alignItems="center"
                        mb="16px"
                        border="1px solid var(--cinza-card)"
                        borderRadius="8px"
                        p="8px"
                      >
                        <Text
                          pl="8px"
                          borderRight="1px solid var(--cinza-card)"
                          w="25%"
                          as="strong"
                        >
                          {data?.log_description}
                        </Text>
                        {/* 
                        <Text
                          pl="8px"
                          borderRight="1px solid var(--cinza-card)"
                          w="25%"
                          as="strong"
                        >
                          {data?.numatendimento}
                        </Text> */}
                        {/* <Text
                          pl="8px"
                          borderRight="1px solid var(--cinza-card)"
                          w="25%"
                        ></Text> */}
                        <Text
                          pl="8px"
                          borderRight="1px solid var(--cinza-card)"
                          w="25%"
                        >
                          {data?.numatendimento}
                        </Text>
                        <Text
                          pl="8px"
                          borderRight="1px solid var(--cinza-card)"
                          w="25%"
                        >
                          {FormatDate(data?.timeAction)}
                          <br />
                          {FormatHour(data?.timeAction)}
                        </Text>
                        <Text pl="8px" w="25%">
                          {data?.ip_user}
                        </Text>
                      </Flex>
                    ))}
                  </Box>
                  <Box
                    h="20px"
                    mt="-20px"
                    backgroundColor="rgba(255,255,255,0.75)"
                    position="relative"
                    top="0"
                    left="0"
                  ></Box>
                </Box>
              </Flex>
            </Flex>
          </Box>

          {openAprove && (
            <AproveDoctor
              setOpenAprove={setOpenAprove}
              name={userProfile.name}
              email={userProfile.email}
              crm={userProfile.crm}
              id={userProfile.id}
            />
          )}
        </Flex>
      )}
    </>
  );
};

const ReportList = styled.div`
  width: 100%;

  & select {
    width: inherit;
    box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, .2);
    box-sizing: border-box;
    font-size: 14px;
    outline: none;

    transition: box-shadow .3s;

    &:not([disabled]) {
      &:hover {
        box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, .4);
      }

      &>option {
        cursor: pointer;

        &:hover {
          color: white;
          background-color: #F3953F;
        }
      }
    }

    &>option {
      transition: background-color .3s, color .2s;
      padding: 0 0 0 .3rem;

      &:checked {
        color: white;
        background-color: #D15D20;
      }
    }
  }
`;

const CardImage = styled.div`
  display: flex;
  margin: 0 auto 16px;
  padding: 8px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0px 0px 24.7967px rgba(0, 0, 0, 0.1);
  img {
    width: 130px;
    height: 130px;
    border-radius: 60px;
    object-fit: cover;
  }
`;

export default Profile;
