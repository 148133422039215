import './style.scss';

const Input = ({
    name,
    placeholder,
    required,
    showLabel
}: {
    name: string;
    placeholder: string;
    required?: boolean;
    showLabel?: boolean;
}) => {
    const labelName = `input-${name}`;

    return (
        <div className='fields_input'>
            {!!showLabel && <label htmlFor={labelName}>{placeholder}</label>}
            <input id={labelName} type='text' name={name} placeholder={showLabel ? undefined : placeholder} required={required} />
        </div>
    );
}

export default Input;