export type PermissionAdministrator = 1;
export type PermissionScheduling = 2;
export type PermissionResult = 3;
export type PermissionAdmBloq = 4;
export type PermissionAgeBloq = 5;
export type PermissionReports = 6;
export type PermissionDoctorTechnique = 7;
export type PermissionTechniqueGeneral = 8;
export type PermissionReception = 9;
export type PermissionTi = 10;

export type Permission =
    PermissionAdministrator |
    PermissionScheduling |
    PermissionResult |
    PermissionAdmBloq |
    PermissionAgeBloq |
    PermissionReports |
    PermissionDoctorTechnique |
    PermissionTechniqueGeneral |
    PermissionReception |
    PermissionTi;

export const PERMISSION_ADMINISTRATOR: PermissionAdministrator = 1;
export const PERMISSION_SCHEDULING: PermissionScheduling = 2;
export const PERMISSION_RESULT: PermissionResult = 3;
export const PERMISSION_ADM_BLOQ: PermissionAdmBloq = 4;
export const PERMISSION_AGE_BLOQ: PermissionAgeBloq = 5;
export const PERMISSION_REPORTS: PermissionReports = 6;
export const PERMISSION_DOCTOR_TECHNIQUE: PermissionDoctorTechnique = 7;
export const PERMISSION_TECHNIQUE_GENERAL: PermissionTechniqueGeneral = 8;
export const PERMISSION_RECEPTION: PermissionReception = 9;
export const PERMISSION_TI: PermissionTi = 10;
