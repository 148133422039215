import Select from "../../../components/Select";

import { Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { Dispatch, SetStateAction, useCallback, useEffect, useRef } from "react";
import { useQuery } from '@tanstack/react-query'
import { useAuth } from "../../../hooks";
import { getHospitals } from "./utils";
import { IActiveHospital } from "../types";

import './style.scss';

const ChangeActiveHospitalModal = ({
    changeActiveHospitalIsOpen,
    setChangeActiveHospitalIsOpen,
    activeHospital,
    setActiveHospital
}: {
    changeActiveHospitalIsOpen: boolean;
    setChangeActiveHospitalIsOpen: Dispatch<SetStateAction<boolean>>;
    activeHospital: IActiveHospital | undefined;
    setActiveHospital: Dispatch<SetStateAction<IActiveHospital | undefined>>;
}) => {
    const auth = useAuth() as { user: { id: number } };

    const getHospitalsRequestRef = useRef<AbortController | null>(null);

    const { data, isLoading } = useQuery({
        queryKey: ['hospital_list', { user_id: auth.user.id }],
        queryFn: async () => {
            getHospitalsRequestRef.current?.abort();
            getHospitalsRequestRef.current = new AbortController();

            return await getHospitals(getHospitalsRequestRef.current.signal);
        },
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 6 * 60 * 60 * 1000
    });

    const changeActiveHospital = useCallback((uuid: string) => {
        const hospital = data?.find(hospital => hospital.uuid === uuid);
        setActiveHospital(hospital?.id ? { id: parseInt(hospital.id), name: hospital?.name, uuid: hospital?.uuid, permissions: hospital.permissions } : undefined);
        setChangeActiveHospitalIsOpen(false);
    }, [data, setActiveHospital, setChangeActiveHospitalIsOpen]);

    useEffect(() => {
        data?.length === 1 && changeActiveHospital(data[0].uuid);
    }, [data, changeActiveHospital]);

    return (
        <>
            <Modal
                onClose={() => setChangeActiveHospitalIsOpen(false)}
                isOpen={changeActiveHospitalIsOpen}
                size="md"
                isCentered
            >
                <ModalOverlay />
                <ModalContent className="active_hospital__modal_content">
                    <header>
                        <span>Selecione o hospital que deseja ter acesso</span>
                    </header>
                    <main>
                        <Select
                            options={data?.map(hospital => ({ value: hospital.uuid, label: hospital.name })) ?? []}
                            closeOnSelect
                            defaultValue={activeHospital?.uuid ? { value: activeHospital.uuid, label: activeHospital.name } : undefined}
                            onChange={option => option?.value && changeActiveHospital(typeof option.value === 'number' ? option.value.toString() : option.value)}
                            javascriptSearch
                            isLoading={isLoading}
                        />
                    </main>
                </ModalContent>
            </Modal>
        </>
    );
}

export default ChangeActiveHospitalModal;