import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { AsideItem, IReport } from "./types";
import { useAuth } from "../../hooks";
import { useActiveHospital } from "../../contexts/ActiveHospital/useActiveHospital";
import { useQuery } from "@tanstack/react-query";
import { useReportModal } from "../../contexts/ReportModal/useReportModal";
import { PERMISSION_ADMINISTRATOR, PERMISSION_REPORTS, PERMISSION_SCHEDULING } from '../../types/permissions';
import { Role, ROLE_OPERATION } from "../../types/role";

import classNames from "classnames";
import LogoIcon from "../../assets/icons/LogoIcon";
import HouseIcon from "../../assets/icons/HouseIcon";
import CalendarSidebarIcon from "../../assets/icons/CalenarSidebarIcon";
import UserSidebarIcon from "../../assets/icons/UserSidebarIcon";
import FolderIcon from "../../assets/icons/FolderIcon";
import ExitIcon from "../../assets/icons/ExitIcon";
import MenuItem from "./MenuItem";
import UserIcon from "../../assets/icons/UserIcon";
import FolderPlusIcon from "../../assets/icons/FolderPlusIcon";

import './style.scss';
import api from "../../services/api";

const getAsideItems = (
    authLogout: () => void,
    handleOpenReportModal: () => void,
    reports: AsideItem[],
    activeHospitalUuid?: string
): AsideItem[] => {
    const token = localStorage.getItem("Auth_senne");
    const userId = localStorage.getItem("User_id_senne");

    return [
        {
            content: 'Início',
            icon: <HouseIcon />,
            href: '/dashboard'
        },
        {
            content: 'Agendamento',
            href: `${process.env.REACT_APP_SCHEDULE_URL}/login/sso?token=${token}&hospitalUuid=${activeHospitalUuid}&userId=${userId}`,
            icon: <CalendarSidebarIcon />,
            permissions: [PERMISSION_SCHEDULING]
        },
        {
            content: 'Usuários',
            href: '/gerenciamento-senne',
            icon: <UserSidebarIcon />,
            permissions: [PERMISSION_ADMINISTRATOR]
        },
        {
            content: 'Novo Relatório',
            href: undefined,
            icon: <FolderPlusIcon />,
            classname: '--from_awesome',
            onClick: () => handleOpenReportModal(),
            role: ROLE_OPERATION
        },
        {
            content: 'Relatório',
            href: undefined,
            icon: <FolderIcon />,
            classname: '--from_awesome',
            searchable: true,
            permissions: [PERMISSION_REPORTS],
            dropdown: reports
        },
        {
            classname: '--logout_bottom',
            content: 'Sair',
            href: '#exit',
            icon: <ExitIcon />,
            onClick: () => authLogout()
        }
    ];
}

const formatReportToAside = (reports: IReport[]): AsideItem[] => {
    return reports.map(report => ({
        content: report.label ?? report.name,
        href: `/relatorio/${report.id}`
    }));
}

const Aside = ({ children }: { children: ReactNode }) => {
    const activeHospital = useActiveHospital();
    const reportModal = useReportModal();

    const auth = useAuth() as { user: { id: number; role_id: Role; hash: string; name: string; hospitals: { name: string; }[]; }, logout: () => void };
    const location = useLocation();

    const asideRef = useRef<HTMLElement>(null);
    const listReportsRef = useRef<AbortController | null>(null);
    const toggleAsideButtonRef = useRef<HTMLDivElement>(null);

    const [asideIsOpen, setAsideIsOpen] = useState(true);

    const { data: reports } = useQuery({
        queryKey: ['reports', auth.user.role_id === 1 ? { role_id: 1 } : { uuid: activeHospital.uuid }], // Condição serve para não fazer requisições desnecessárias para role_id = 1, considerando que ele sempre terá todos relatórios disponíveis
        queryFn: async () => {
            listReportsRef.current?.abort();
            listReportsRef.current = new AbortController();

            return (await api.get<{ reports: IReport[] }>('/report', { params: { uuid: activeHospital.uuid } })).data.reports;
        },
        enabled: !!activeHospital.uuid || auth.user.role_id === ROLE_OPERATION,
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 6 * 60 * 60 * 1000
    });

    const menuItems = useMemo(
        () => {
            const menuItems = getAsideItems(
                auth.logout,
                () => reportModal.setUpdateReportModalIsOpen(true),
                formatReportToAside(reports ?? []),
                activeHospital.uuid
            ).filter(
                item => auth.user.role_id === ROLE_OPERATION ||
                (!item.permissions && !item.role) ||
                item.permissions?.some(permission => activeHospital.permissions?.includes(permission)) ||
                item.role === auth.user.role_id
            );

            return <MenuItem
                asideItems={menuItems}
                pathname={location.pathname}
            />
        },
        [activeHospital.permissions, activeHospital.uuid, auth.logout, auth.user.role_id, reportModal, location.pathname, reports]
    );

    const footer = useMemo(() => <Footer />, []);

    useEffect(() => {
        const handleAsideClickOutside = (e: MouseEvent) => {
            if (
                window.innerWidth <= 768 &&
                asideRef.current &&
                toggleAsideButtonRef.current &&
                !asideRef.current.contains(e.target as Node) &&
                !toggleAsideButtonRef.current.contains(e.target as Node)
            ) {
                setAsideIsOpen(false);
            }
        };

        window.addEventListener('click', handleAsideClickOutside);

        return () => window.removeEventListener('click', handleAsideClickOutside);
    }, []);

    useEffect(() => {
        window.innerWidth <= 768 && setAsideIsOpen(false);
    }, [location.pathname]);

    return (
        <>
            <header className="header">
                <div className="header_logo">
                    <div className="icon">
                        <LogoIcon />
                    </div>
                    <div
                        ref={toggleAsideButtonRef}
                        className={classNames('header_aside_toggle', { '--aside_is_open': asideIsOpen })}
                        onClick={() => setAsideIsOpen(prev => !prev)}
                    />
                </div>
                <div className="header_hospital_active" onClick={() => activeHospital.setChangeActiveHospitalIsOpen(true)}>
                    <span>{activeHospital.name || 'Nenhum hospital selecionado'}</span>
                </div>
                <div className="header_user">
                    <div className="header_user_content">
                        <div className="header_user_content_name">
                            {auth.user.name}
                        </div>
                    </div>
                    <div className="header_user_image">
                        <UserIcon />
                    </div>
                </div>
            </header>
            <aside
                ref={asideRef}
                className={classNames('aside', { '--aside_is_open': asideIsOpen })}
                children={menuItems}
            />
            <div className={classNames('aside_content', { '--aside_is_open': asideIsOpen })} children={children} />
            <footer className="footer" children={footer} />
        </>
    );
}

export default Aside;

const Footer = () => {
    const [date, setDate] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => setDate(new Date()), 60000);
        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <div className="footer_datetime">
                {new Intl.DateTimeFormat(
                    'pt-BR',
                    {
                        weekday: 'long',
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric'
                    }
                ).format(date)} - {new Intl.DateTimeFormat(
                    'pt-BR',
                    {
                        hour: '2-digit',
                        minute: '2-digit'
                    }
                ).format(date)}
            </div>
        </>
    );
}