import { IReport } from "../../../components/Aside/types";
import api from "../../../services/api"

export const getReportDetails = async (reportId: number, signal?: AbortSignal) => {
    return (await api.get<IReport>(`/report/${reportId}`, { signal })).data;
}

export const createOrUpdateReport = async (name: string, label?: string, reportId?: number) => {
    return (await api.put<{ report_id: number }>(`/report${reportId ? `/${reportId}` : ''}`, { name, label })).data;
}

export const createOrUpdateReportParams = async (params: any, reportId?: number) => {
    return (await api.put(`/report/param/${reportId}`, params)).data;
}

export const removeReport = async (reportId: number) => {
    return (await api.delete(`/report/${reportId}`));
}