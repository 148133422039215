import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../hooks";

import { routes, authenticatedRoutes } from "./constants";
import Aside from "../components/Aside";
import { ActiveHospitalProvider } from "../contexts/ActiveHospital";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReportModalProvider } from "../contexts/ReportModal";

export const RequireAuth = ({ children }) => {
  const { isLoggedIn } = useAuth();
  const location = useLocation();

  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

const queryClient = new QueryClient();

export default function Router() {

  return (
    <Routes>
      {routes.map(({ path, element }, index) => (
        <Route key={index} path={path} element={element} />
      ))}
      {authenticatedRoutes.map(({ path, element }, index) => (
        <Route
          key={index}
          path={path}
          element={
            <RequireAuth>
              <QueryClientProvider client={queryClient}>
                <ActiveHospitalProvider>
                  <ReportModalProvider>
                    <Aside>
                      {element}
                    </Aside>
                  </ReportModalProvider>
                </ActiveHospitalProvider>
              </QueryClientProvider>
            </RequireAuth>
          }
        />
      ))}
    </Routes>
  );
}
