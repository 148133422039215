import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Flex, Box, Text, Switch } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useUsers, useAuth, useHospitals } from "../../hooks";
import { PrimaryResultsButton } from "../../components/Buttons";
import { useActiveHospital } from "../../contexts/ActiveHospital/useActiveHospital";

import * as yup from "yup";
import TextSelectHospital from "../../components/TextSelectHospital";
import TextInput from "../../components/TextInput";
import Loading from "../../components/Loading";
import Avatar from "../../images/avatar.svg";
import api from "../../services/api";
import styled from "styled-components";
import { useQuery } from "@tanstack/react-query";
import { PERMISSION_REPORTS } from "../../types/permissions";
import Select from "../../components/Select";

const getReportList = async (uuid) => (await api.get('/report', { params: { uuid } })).data.reports;

const schema = yup.object().shape({
  name: yup.string().required("O campo nome é obrigatório"),
  email: yup.string().email().required("O campo email é obrigatório"),
  cpf: yup.string().required("O campo CPF é obrigatório"),
  cellphone: yup.string().required("O campo telefone é obrigatório"),
  hospitals: yup.string().required("O campo hospitais é obrigatório"),
  permiteContato: yup.boolean().required("O campo permite contato é obrigatório"),
  report: yup.boolean(),
  report_permissions: yup.array()
});

export default function AddUser() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const listReportsRef = useRef(null);
  const activeHospital = useActiveHospital();

  const [uuid, setUUID] = useState([]);
  const [selectedUUID, setSelectedUUID] = useState([]);
  const [codProcedencia, setCodProcedencia] = useState([]);

  const [profileImage, setProfileImage] = useState(null);
  const [hospitalsList, setHospitalsList] = useState();
  const [photoBase64, setPhotoBase64] = useState();
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState();
  const [error, setError] = useState();

  const { createUser, editUserImage } = useUsers();
  const { listHospital, getHospitals } = useHospitals();

  const { getHospitalsList, getUserHospitalList } = useHospitals();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const { data: reportList } = useQuery({
    queryKey: ['reports', user.role_id === 1 ? { role_id: 1 } : { uuid: activeHospital.uuid }],
    queryFn: () => {
      listReportsRef.current?.abort();
      listReportsRef.current = new AbortController();

      return getReportList(activeHospital.uuid);
    },
    enabled: !!watch().report,
    retry: false,
    staleTime: 1 * 60 * 60 * 1000
  });

  function getNames(objs, uuids) {
    return objs
      .filter((obj) => uuids.includes(obj.uuid))
      .map((obj) => obj.name);
  }

  function getProcedencia(objs, uuids) {
    return objs
      .filter((obj) => uuids.includes(obj.uuid))
      .map((obj) => obj.id_hospital);
  }

  function removeProcSelected(index) {
    uuid.splice(index, 1);
    selectedUUID.splice(index, 1);
    setUUID(uuid);
    setSelectedUUID(getNames(hospitalsList, uuid));
    setCodProcedencia(getProcedencia(hospitalsList, uuid));
  }

  async function getHospitalList() {
    const hospital = await getHospitalsList();

    const hospitalList = hospital.map((item) => ({
      uuid: item.uuid,
      name: item.name,
    }));

    setHospitalsList(hospitalList);
  }

  function listProc(value) {
    try {
      if (!value) {
        return;
      }

      if (uuid.includes(value)) {
        return;
      }

      setUUID(value);
      setSelectedUUID(getNames(hospitalsList, value));
      setCodProcedencia(getProcedencia(hospitalsList, value));
    } catch (error) {
      console.log(error);
    }
  }

  async function getHospital() {
    try {
      const role_id = JSON.parse(localStorage.getItem("User_senne"));

      if (role_id?.role_id === 1) {
        const results = await getHospitals();
        setHospitalsList(results?.Hospitals);
      } else {
        const results = await getUserHospitalList();
        setHospitalsList(results?.hospitals);
      }
    } catch (error) {
      console.error(error);
      setError(error);
    }
  }

  async function uploadImage() {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("image", profileImage);
      formData.append("id_user", userId);
      await editUserImage(formData);
      navigate(`/users`);
    } catch (error) {
      console.error(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  function getBase64() {
    try {
      let document = "";
      let reader = new FileReader();
      reader.readAsDataURL(profileImage);
      reader.onloadend = function (e) {
        document = reader.result;
        setPhotoBase64(document);
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    } catch (error) {
      console.error(error);
      setError(error);
    }
  }

  const submit = async (values) => {
    try {
      setLoading(true);
      const permissions = [];
      let hospital_id = ''
      for (let i = 0; i < hospitalsList.length; i += 1) {
        if (values.hospitals === hospitalsList[i].uuid) {
          hospital_id = hospitalsList[i].id ?? hospitalsList[i].id_hospital
        }
      }

      if (values.administrator) {
        permissions.push(1);
      }
      if (values.scheduling) {
        permissions.push(2);
      }
      if (values.results) {
        permissions.push(3);
      }

      values?.report && permissions.push(PERMISSION_REPORTS);

      const values_api = {
        crm: "",
        ...values,
        hospitals: hospital_id,
        permissions: permissions,
        report_permissions: values?.report_permissions ?? []
      };

      const result = await createUser({
        cellphone: values_api.cellphone,
        cpf: values_api.cpf,
        email: values_api.email,
        name: values_api.name,
        hospitals: hospital_id,
        permissions: permissions,
        permiteContato: values_api.permiteContato,
        report_permissions: values?.report_permissions ?? []
      });
      setUserId(result?.data?.data?.id);

      if (result.status === 200) {
        navigate(`/users`);
      }


    } catch (error) {
      console.error(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userId) {
      uploadImage();
    }
  }, [userId]);

  useEffect(() => {
    if (profileImage) {
      getBase64();
    }
  }, [profileImage]);

  useEffect(() => {
    getHospital();
  }, [])

  if (error) {
    return (
      <div>
        <p>Erro encontrado!</p>
      </div>
    );
  }

  return (
    <Flex>
      {loading && <Loading />}
      <Box p="1rem" w='100%'>

        <Box
          border="1px solid var(--cinza-card)"
          borderRadius="10px"
          p="46px 60px"
          backgroundColor="white"
        >
          <Flex alignContent="center" alignItems="center" gap="15px" mb="16px">
            <img
              width="150px"
              src={profileImage ? photoBase64 : Avatar}
              alt="foto"
            />

            <input
              type="file"
              name="profileImage"
              accept="image/png, image/jpeg"
              onChange={(event) => setProfileImage(event.target.files[0])}
              style={{
                position: "absolute",
                width: "100%",
                opacity: 0,
                cursor: "pointer",
              }}
            ></input>

            <Text as="strong" fontSize="1.4rem">
              Adicionar foto de perfil
            </Text>
          </Flex>

          <form onSubmit={handleSubmit(submit)}>
            <Box pr="39px">
              <Flex justifyContent="space-around" gap="100px">
                <TextInput
                  isError={errors.name}
                  register={{ ...register("name") }}
                  labelDescription="Nome Completo"
                  InputPlaceholder="Nome Completo"
                  name="name"
                ></TextInput>

                <TextInput
                  isError={errors.cpf}
                  register={{ ...register("cpf") }}
                  maskInput="***.***.***-**"
                  labelDescription="CPF"
                  InputPlaceholder="123.456.789-12"
                  name="cpf"
                />
              </Flex>
              <Flex justifyContent="space-around" gap="100px">
                <TextInput
                  isError={errors.cellphone}
                  register={{ ...register("cellphone") }}
                  maskInput="(**) *****-****"
                  labelDescription="Qual seu telefone"
                  InputPlaceholder="(99) 99999-9999"
                  name="cellphone"
                />

                <TextInput
                  isError={errors.email}
                  register={{ ...register("email") }}
                  labelDescription="Email"
                  InputPlaceholder="email@email.com.br"
                  name="email"
                ></TextInput>
              </Flex>
              <Flex gap="100px">
                <Flex w="100%" flexDirection="column">
                  <Flex w="100%" mt="10px" flexDirection="column">
                    <TextSelectHospital
                      isError={errors.hospitals}
                      register={{ ...register("hospitals") }}
                      labelDescription="Seleciona as unidades"
                      mt="7px"
                      name="hospitals"
                      titleOption="Unidades"
                      w="100%"
                      options={hospitalsList}
                      onChange={(e) => {
                        listProc(e.target.value);
                        setCodProcedencia(e.target.value)
                      }}
                    />
                  </Flex>
                </Flex>
                <Flex flexDirection="column" w="100%" gap="20px">
                  <Text as="strong">Permissões</Text>
                  <Flex gap="40px">
                    <Flex
                      justifyContent="center"
                      alignContent="center"
                      alignItems="center"
                      gap="10px"
                    >
                      <Switch
                        size="md"
                        colorScheme="orange"
                        name="scheduling"
                        id="scheduling"
                        {...register("scheduling")}
                      />
                      <Text w="200px">Agendamento</Text>
                    </Flex>
                    <Flex
                      justifyContent="center"
                      alignContent="center"
                      alignItems="center"
                      gap="10px"
                    >
                      <Switch
                        size="md"
                        colorScheme="orange"
                        name="results"
                        id="results"
                        {...register("results")}
                      />
                      <Text>Resultados</Text>
                    </Flex>
                  </Flex>
                  <Flex gap="40px">
                    <Flex
                      justifyContent="center"
                      alignContent="center"
                      alignItems="center"
                      gap="10px"
                    >
                      <Switch
                        size="md"
                        colorScheme="orange"
                        name="administrator"
                        id="administrator"
                        {...register("administrator")}
                      />
                      <Text w="200px">Administrador</Text>
                    </Flex>
                    <Flex
                      justifyContent="center"
                      alignContent="center"
                      alignItems="center"
                      gap="10px"
                    >
                      <Switch
                        size="md"
                        colorScheme="orange"
                        name="permiteContato"
                        id="permiteContato"
                        {...register("permiteContato")}
                      />
                      <Text>Ponto Focal para Resultados Críticos</Text>
                    </Flex>
                    <Flex
                      justifyContent="center"
                      alignContent="center"
                      alignItems="center"
                      gap="10px"
                    ></Flex>
                  </Flex>
                  <Flex gap="40px">
                    <Flex
                      justifyContent="center"
                      gap="10px"
                    >
                      <Switch
                        size="md"
                        colorScheme="orange"
                        name="report"
                        id="report"
                        {...register("report")}
                      />
                      <Text w="200px">Relatórios</Text>
                    </Flex>
                    <Flex
                      justifyContent="center"
                      alignContent="center"
                      alignItems="center"
                      gap="10px"
                      width='100%'
                    >
                      {!!watch().report &&
                        <ReportList>
                          <Controller
                            name="report_permissions"
                            control={control}
                            render={
                              ({ field }) =>
                                <Select
                                  multiple
                                  options={reportList.map(report => ({ value: report.id, label: report.label ?? report.name }))}
                                  onChange={(options) => field.onChange(options.map(option => option.value))}
                                  onBlur={(options) => field.onBlur(options.map(option => option.value))}
                                />
                            }
                          />
                        </ReportList>
                      }
                    </Flex>
                    <Flex
                      justifyContent="center"
                      alignContent="center"
                      alignItems="center"
                      gap="10px"
                    ></Flex>
                  </Flex>
                </Flex>
              </Flex>
              <Flex justifyContent="end" mt="20px">
                <Box w="121px" h="30px">
                  <PrimaryResultsButton type="submit">
                    Continuar
                  </PrimaryResultsButton>
                </Box>
              </Flex>
            </Box>
          </form>
        </Box>
      </Box>
    </Flex>
  );
}

const ReportList = styled.div`
  width: 100%;

  & select {
    width: 100%;
    box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, .2);
    box-sizing: border-box;
    font-size: 14px;
    outline: none;

    transition: box-shadow .3s;

    &:hover {
      box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, .4);
    }

    &>option {
      cursor: pointer;
      transition: background-color .3s, color .2s;
      padding: 0 0 0 .3rem;

      &:hover {
        color: white;
        background-color: #F3953F;
      }

      &:checked {
        color: white;
        background-color: #D15D20;
      }
    }
  }
`;