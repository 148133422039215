function PencilSquareIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <g fill="#343A40" clipPath="url(#clip0_4701_191985)">
        <path d="M19.166 2.399a.618.618 0 010 .873l-1.29 1.29-2.472-2.472L16.694.8a.618.618 0 01.874 0l1.598 1.598v.001zm-2.163 3.037L14.53 2.963l-8.424 8.425a.618.618 0 00-.15.242l-.994 2.984a.31.31 0 00.39.391l2.985-.995a.617.617 0 00.242-.148l8.424-8.426z"></path>
        <path
          fillRule="evenodd"
          d="M1.236 16.692a1.855 1.855 0 001.855 1.854h13.6a1.854 1.854 0 001.855-1.854V9.273a.618.618 0 10-1.237 0v7.419a.618.618 0 01-.618.618h-13.6a.618.618 0 01-.618-.618v-13.6a.618.618 0 01.618-.619h8.036a.618.618 0 100-1.236H3.091A1.855 1.855 0 001.236 3.09v13.6z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_4701_191985">
          <path fill="#fff" d="M0 0H19.782V19.782H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default PencilSquareIcon;
