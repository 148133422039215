import classNames from "classnames";
import { AsideItem } from "../types";
import { Link } from "react-router-dom";
import { useState } from "react";

const MenuItem = ({ pathname, asideItems, searchable }: { pathname: string; asideItems: AsideItem[], searchable?: boolean }) => {
    const [search, setSearch] = useState<string | undefined>(undefined);

    const filteredAsideItems = asideItems.filter(asideItem => asideItem.href || (!asideItem.href && asideItem.dropdown?.length !== 0));

    return (
        <ul>
            {(searchable && asideItems.length > 0) && <li children={<input type='text' name="search" placeholder="Buscar" onChange={(e) => setSearch(e.target.value)} />} />}
            {filteredAsideItems.map((asideItem, index) => {
                const regex = new RegExp(search ?? '', "i");

                return (
                    <li
                        onClick={asideItem.onClick}
                        key={index}
                        className={classNames(
                            asideItem.classname,
                            {
                                '--is_dropdown': !!asideItem.dropdown?.length,
                                '--hidden': !regex.test(asideItem.content),
                                '--active': pathname === asideItem.href
                            }
                        )}>
                        <Link to={asideItem.href ?? '#'}>
                            {asideItem.icon && <i>{asideItem.icon}</i>}
                            <span>{asideItem.content}</span>
                        </Link>
                        {asideItem.dropdown && <MenuItem pathname={pathname} asideItems={asideItem.dropdown} searchable={asideItem.searchable} />}
                    </li>
                )
            })}
        </ul>
    );
}

export default MenuItem;