import { useState } from "react";
import { useAuth } from "../../../hooks";
import { Button, Input, Radio, RadioGroup } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { CalendarIcon } from "@chakra-ui/icons";

import './style.scss';

const Filter = ({ filterIsLoading }: { filterIsLoading: boolean }) => {
  const { user } = useAuth() as { user: { role_id: number; hash: string; } };

  const [isOpenDateStart, setIsOpenDateStart] = useState(false);
  const startDay = (new Date()).setDate(new Date().getDate() - (user.role_id === 3 ? 1825 : 60));
  const [selectedStartDate, setSelectedStartDate] = useState(new Date(startDay));

  const [isOpenDateEnd, setIsOpenDateEnd] = useState(false);
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());

  return (
    <>
      {user?.role_id !== 5 && user?.role_id !== 3 && (
        <label className="search">
          <span>Pesquisar:</span>
          <Input
            padding="0"
            border="none"
            placeholder="Procure por nome do paciente"
            name="name"
          />
        </label>
      )}
      <div className="filters">
        <div className="label">
          Filtros
        </div>
        <div className="field">
          <RadioGroup
            name="abnormal"
            defaultValue="0"
            children={
              <>
                <Radio value="0" pr={8} children='Todos' />
                <Radio value="1" defaultChecked children='Fora do Valor de Referência' />
              </>
            }
          />
        </div>
      </div>
      <div className="start_date">
        <div className="label">
          Data Início
        </div>
        <div className="field">
          <label>
            <DatePicker
              open={isOpenDateStart}
              name="start_date"
              locale="ptBR"
              dateFormat="dd/MM/yyyy"
              onChange={(date) => date && setSelectedStartDate(date)}
              selectsStart
              selected={selectedStartDate}
              startDate={selectedStartDate}
              endDate={selectedEndDate}
              maxDate={selectedEndDate}
              onClickOutside={() => setIsOpenDateStart(false)}
              onFocus={() => setIsOpenDateStart(true)}
            />
            <CalendarIcon
              color="orange"
              cursor="pointer"
              onClick={() => setIsOpenDateStart(prev => !prev)}
            />
          </label>
        </div>
      </div>
      <div className="end_date">
        <div className="label">
          Data Fim
        </div>
        <div className="field">
          <label>
            <DatePicker
              open={isOpenDateEnd}
              name="end_date"
              locale="ptBR"
              dateFormat="dd/MM/yyyy"
              onChange={(date) => date && setSelectedEndDate(date)}
              selectsEnd
              selected={selectedEndDate}
              startDate={selectedStartDate}
              endDate={selectedEndDate}
              minDate={selectedStartDate}
              onClickOutside={() => setIsOpenDateEnd(false)}
              onFocus={() => setIsOpenDateEnd(true)}
            />
            <CalendarIcon
              color="orange"
              cursor="pointer"
              onClick={() => setIsOpenDateEnd(prev => !prev)}
            />
          </label>
        </div>
      </div>
      <div className="actions">
        <Button
          border="1px solid #CED4DA"
          w="100%"
          backgroundColor="var(--orange-senne)"
          _hover={{ opacity: 0.7 }}
          p="10px 10px"
          color="white"
          children='Pesquisar'
          type="submit"
          isLoading={filterIsLoading}
        />
      </div>
    </>
  );
}

export default Filter;