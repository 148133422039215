import { createContext, useEffect, useMemo, useState } from "react";
import { IActiveHospital, IActiveHospitalProvider, IContext } from "./types";
import { getActiveHospitalStorage, setActiveHospitalStorage } from "./utils";

import ChangeActiveHospitalModal from "./ChangeActiveHospitalModal";

export const ActiveHospitalContext = createContext<IContext>({} as IContext);

export const ActiveHospitalProvider = ({ children }: IActiveHospitalProvider) => {
    const [activeHospital, setActiveHospital] = useState<IActiveHospital | undefined>();
    const [changeActiveHospitalIsOpen, setChangeActiveHospitalIsOpen] = useState<boolean>(false);

    useEffect(() => {
        const activeHospitalFromStorage = getActiveHospitalStorage();

        !activeHospitalFromStorage && setChangeActiveHospitalIsOpen(true);
        activeHospitalFromStorage && setActiveHospital(activeHospitalFromStorage);
    }, []);

    useEffect(() => {
        setActiveHospitalStorage(activeHospital);
    }, [activeHospital]);

    const changeActiveHospitalModal = useMemo(
        () => <ChangeActiveHospitalModal
            changeActiveHospitalIsOpen={changeActiveHospitalIsOpen}
            setChangeActiveHospitalIsOpen={setChangeActiveHospitalIsOpen}
            activeHospital={activeHospital}
            setActiveHospital={setActiveHospital}
        />, [
            changeActiveHospitalIsOpen,
            setChangeActiveHospitalIsOpen,
            activeHospital,
            setActiveHospital
        ]
    );

    return (
        <ActiveHospitalContext.Provider value={{ ...activeHospital, setChangeActiveHospitalIsOpen }}>
            {changeActiveHospitalModal}
            {children}
        </ActiveHospitalContext.Provider>
    );
}
