import { useEffect, useRef, useState } from 'react';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist';

import classNames from 'classnames';

import './style.scss';

GlobalWorkerOptions.workerSrc = '/pdf.worker.js';

const PdfViewer = ({ fileBinary }: { fileBinary?: Blob; }) => {
    const canvasContainerRef = useRef<HTMLDivElement | null>(null);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => { canvasContainerRef.current!.replaceChildren(); }, [fileBinary]);

    useEffect(() => {
        const loadPdf = async () => {
            if (fileBinary) {
                const reader = new FileReader();

                reader.onload = async () => {
                    setIsLoading(true);

                    const typedArray = new Uint8Array(reader.result as ArrayBuffer);

                    const pdf = await getDocument(typedArray).promise;

                    for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
                        const loadedPage = await pdf.getPage(pageNum);

                        const canvas = document.createElement('canvas');
                        const canvasContext = canvas.getContext('2d');

                        if (canvasContext) {
                            const viewport = loadedPage.getViewport({ scale: 1.5 });

                            canvas.width = viewport.width;
                            canvas.height = viewport.height;

                            await loadedPage.render({ canvasContext: canvasContext, viewport: viewport }).promise;

                            canvasContainerRef.current?.appendChild(canvas);
                        }
                    }

                    setIsLoading(false);
                };

                reader.readAsArrayBuffer(fileBinary);
            }
        };

        loadPdf();
    }, [fileBinary]);

    const forceDownload = () => {
        if (!fileBinary) {
            return;
        }

        const objectUrl = URL.createObjectURL(fileBinary);
        const link = document.createElement('a');

        link.href = objectUrl
        link.download = generateFilename();
        link.click();

        URL.revokeObjectURL(objectUrl);
    }

    return (
        <>
            <div className={classNames('pdf_result', { '--has_content': !!fileBinary })}>
                <div className='pdf_result__canvas' ref={canvasContainerRef} />
                {fileBinary && <button disabled={isLoading} className={classNames('pdf_result__download', { '--is_loading': isLoading })} onClick={forceDownload} />}
            </div>
        </>
    )
};

export default PdfViewer;

const generateFilename = () => {
    const now = new Date();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
}