import { createContext, useEffect, useMemo, useState } from "react";
import { IContext, IReportModalProvider } from "./types";
import UpdateReportModal from "./UpdateReportModal";

export const ReportModalContext = createContext<IContext>({} as IContext);

export const ReportModalProvider = ({ children }: IReportModalProvider) => {
    const [updateReportModalIsOpen, setUpdateReportModalIsOpen] = useState<boolean>(false);
    const [activeReportId, setActiveReportId] = useState<number | undefined>(undefined);

    useEffect(() => { !updateReportModalIsOpen && setActiveReportId(undefined); }, [updateReportModalIsOpen]);

    const updateReportModal = useMemo(() =>
        <UpdateReportModal
            activeReportId={activeReportId}
            updateReportModalIsOpen={updateReportModalIsOpen}
            setUpdateReportModalIsOpen={setUpdateReportModalIsOpen}
        />,
        [activeReportId, updateReportModalIsOpen, setUpdateReportModalIsOpen]
    );

    return (
        <ReportModalContext.Provider value={{ updateReportModalIsOpen, setUpdateReportModalIsOpen, setActiveReportId }}>
            {updateReportModal}
            {children}
        </ReportModalContext.Provider>
    );
}
